export const CheckOldWebSiteUrl = (url: string) => {
  const redirectRoule = {
    home: [
      //
      '/sitemap',
      '/privacy',
      '/cars',
      '/category/news-ja',
      '/category/recommends-ja',
      '/category/recommends-ja?post_type=cars',
      '/news-ja/321',
      '/en/sitemap',
      '/en/privacy',
      '/en/cars',
      '/en/category/news-en',
      '/en/cars/honda-civic-type-r-2',
      '/en/category/recommends-ja',
      '/en/category/recommends-en',
      '/en/news-ja/321',
    ],
    about: [
      //
      '/about',
      '/en/about',
    ],
    contact: [
      //
      '/contact',
      '/en/contact',
    ],
  };
  const lang = GetLang(url);
  // console.log('CheckOldWebSiteUrl >> ', url);
  if (redirectRoule.home.includes(url)) {
    const nextUrl = `/${lang}/`;
    console.log('Redirect >> ', url, '>', nextUrl);
    return nextUrl;
  } else if (redirectRoule.about.includes(url)) {
    const nextUrl = `/${lang}/about-us`;
    // console.log('Redirect >> ', url, '>', nextUrl);
    return nextUrl;
  } else if (redirectRoule.contact.includes(url)) {
    const nextUrl = `/${lang}/contact-us`;
    // console.log('Redirect >> ', url, '>', nextUrl);
    return nextUrl;
  }
  return null;
};

export const GetLang = (url: string) => {
  // console.log('GetLang url:', url);
  const ret = url.replace(/^\/(ja|en)\/.*/, '$1');
  if (ret === 'ja' || ret === 'en') return ret;
  return null;
};

export const GaFunc = {
  redirect: 'redirect',
  navigate: 'navigate',
  contactSendAfter: 'contact_send_after',
  contactSendBefore: 'contact_send_before',
} as const;
